@font-face {
  font-family: "wetter-fonticons";
  src: url("fonts/wetter-fonticons.eot?9hgava");
  src: url("fonts/wetter-fonticons.eot?9hgava#iefix")
      format("embedded-opentype"),
    url("fonts/wetter-fonticons.ttf?9hgava") format("truetype"),
    url("fonts/wetter-fonticons.woff?9hgava") format("woff"),
    url("fonts/wetter-fonticons.svg?9hgava#wetter-fonticons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "wetter-fonticons", monospace !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-menu:before {
  content: "\e901";
}

.icon-gitlab:before {
  content: "\e900";
}

.icon-play2:before {
  content: "\ea15";
}

.icon-pause:before {
  content: "\ea16";
}

.icon-instagram:before {
  content: "\ea92";
}

.icon-twitter:before {
  content: "\ea96";
}

.icon-youtube:before {
  content: "\ea9d";
}
