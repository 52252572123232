:root {
  --primary: #8ea6d0;
  --darker-primary: #6a8ac1;
  --favorite: hsl(150, 100%, 50%);

  --secondary: #d6aaa9;
  --darker-secondary: #c68886;

  --dark-grey: #191919;
  --middle-grey: #3f3f3f;
  --normal-grey: #999;
  --medi-light-grey: #e0e0e0;
  --light-grey: #f2f2f2;

  --color: #333;
  --bg-color: #f2f2f2;
  --main-bg-color: #ffffff;

  --diagonal-height: 4rem;

  --size-1: 25px;
  --size-2: 50px;
  --size-3: 100px;
}

@media screen and (min-width: 992px) {
  :root {
    --diagonal-height: 8rem;
  }
}

body.page-home {
  --color: rgba(255, 255, 255, 0.8);
  --bg-color: #222;
  --main-bg-color: #333;
}
