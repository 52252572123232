/* diagonal */

.diagonal {
  position: relative;
  z-index: 1;
  padding: var(--diagonal-height) 0;
}

.diagonal.no-after {
  padding-bottom: 0;
}

.diagonal::before,
.diagonal:not(.no-after)::after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  height: var(--diagonal-height);
}

.diagonal::before {
  top: 0;
  background-image: linear-gradient(
    to left bottom,
    transparent 50%,
    var(--main-bg-color) calc(50% + 1px)
  );
}

.diagonal:not(.no-after)::after {
  bottom: 0;
  background-image: linear-gradient(
    to right bottom,
    var(--main-bg-color) 50%,
    transparent calc(50% + 1px)
  );
}

.diagonal-body {
  background-color: var(--main-bg-color);
  color: var(--color);
}

.diagonal.light {
  --main-bg-color: #fff;
  --color: #333;
}

/* between */

.between {
  background-image: linear-gradient(rgba(34, 34, 34, 0.8), #ff0045);
  margin: calc(var(--diagonal-height) * -1) 0;
}

.between > * {
  position: relative;
  z-index: 1;
}

/* parallax */

.parallax-wrapper {
  perspective: 1px;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.parallax-foreground {
  transform-style: preserve-3d;
}

.parallax-background {
  position: absolute;
  transform: translateZ(-1px) scale(5);
  transform-origin: center top;
  transform-style: preserve-3d;
  width: 100vw;
  top: -50%;
}

@media screen and (min-width: 480px) and (max-width: 767px) {
  .parallax-background {
    transform: translateZ(-1px) scale(4);
  }
}

@media screen and (min-width: 768px) and (max-width: 1079px) {
  .parallax-background {
    transform: translateZ(-1px) scale(2.5);
  }
}

@media screen and (min-width: 1080px) {
  .parallax-background {
    transform: translateZ(-1px) scale(2);
  }
}
