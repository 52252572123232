* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

:root {
  font-size: 21px;
}

body {
  font-family: "IBM Plex Mono", monospace;
  font-size: 1rem;
  margin: 0;

  background-color: var(--main-bg-color);
  color: var(--color);
}

p {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

input,
textarea,
button {
  font: inherit;
  color: inherit;
}

a {
  color: var(--primary);
  transition: color 0.2s;
}

a:hover {
  color: var(--darker-primary);
}

a.animated-link {
  text-decoration: none;
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: 0 1px;
  background-image: linear-gradient(
    var(--darker-primary),
    var(--darker-primary)
  );
  padding-bottom: 1px;
  transition: background-size 500ms ease-in-out;
}

a.animated-link:hover {
  background-size: 100% 1px;
}

/* */

h1,
h2 {
  font-weight: 300;
  word-spacing: -3px;
}

h1 {
  text-transform: uppercase;
}

p {
  font-weight: 300;
  line-height: 1.4;
  letter-spacing: 0.6px;
}

html:lang(en) p {
  font-size: 1.1rem;
}

.logo {
  position: relative;
}

.logo-img {
  width: 200px;
}

@media screen and (min-width: 992px) {
  .logo-img {
    position: absolute;
    width: 365px;
    left: 50%;
    transform: translateX(-100%);
    /* margin-left: -3rem; */
  }

  .logo-text {
    padding-top: 8rem;
  }
}

#contact {
  min-height: 100vh;
}

#contact .diagonal-body {
  min-height: calc(100vh - var(--diagonal-height));
}

#contact label.form-input-label,
#contact label.form-textaraea-label {
  display: block;
  color: rgba(0, 0, 0, 0.7);
  text-align: initial;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 2px;
}

#contact label.form-textaraea-label {
  margin-bottom: 10px;
}
