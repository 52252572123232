.text-center {
  text-align: center;
}

.text-favorite {
  color: var(--favorite);
}

.font-normal {
  font-size: 1rem;
}

.btn {
  border: none;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  background-color: var(--primary);
  color: var(--color) !important;
  padding: 0.5rem 0.7rem;
  margin-bottom: 5px;
  border-radius: 3px;
  transition: background-color 0.2s;
}

.btn:hover {
  background-color: var(--darker-primary);
}

.btn:active {
  background-color: var(--darker-secondary);
}

.btn.light {
  --color: #333;
  --primary: rgba(255, 255, 255, 0.8);
  --darker-primary: rgba(255, 255, 255, 1);
}

.btn.favorite {
  --color: #333;
  --primary: var(--favorite);
  --darker-primary: hsl(150, 100%, 40%);
}

.lg {
  padding: 0.8rem 1rem;
}

.container {
  padding: 0 calc(50% - 24rem);
  margin: 0 1rem;
}

.container.sm {
  padding: 0 calc(50% - 14rem);
}

.container.xs {
  padding: 0 calc(50% - 8rem);
}

.container-fluid {
  padding: 1rem;
}

.container::before,
.container::after,
.container-fluid::before,
.container-fluid::after,
.normalize-block {
  content: "";
  display: table;
}

.hidden-link:not(:hover) {
  color: inherit;
  text-decoration: none;
}

.animation-spin {
  display: inline-block;
  animation: spinAnimation 1s infinite linear;
}

@keyframes spinAnimation {
  0% {
    transform: rotate(0deg);
    color: var(--primary);
  }
  100% {
    transform: rotate(359deg);
    color: var(--secondary);
  }
}

.appear {
  animation: appear 0.5s;
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.form-group {
  margin-bottom: 2rem;
}

.form-control {
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid;
  margin-bottom: 1px;
  transition: border-color 0.5s;
}

.form-control:focus {
  outline: none;
  border-width: 2px;
  border-color: var(--favorite);
  margin-bottom: 0;
}

textarea.form-control {
  border: 1px solid;
  height: 200px;
}

.pt-3,
.py-3 {
  padding-top: var(--size-3);
}

.pb-3,
.py-3 {
  padding-bottom: var(--size-3);
}

.pt-3,
.py-3 {
  padding-top: var(--size-3);
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.mt-0 {
  margin-top: 0;
}

.mb-1,
.my-1 {
  margin-bottom: var(--size-1);
}

.mt-2,
.my-2 {
  margin-top: var(--size-2);
}

.mb-2,
.my-2 {
  margin-bottom: var(--size-2);
}

.mt-3,
.my-3 {
  margin-top: var(--size-3);
}

.mb-3,
.my-3 {
  margin-bottom: var(--size-3);
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.d-block {
  display: block;
}

/* row */

@media screen and (min-width: 992px) {
  .row {
    display: flex;
  }

  .col-4 {
    width: 33%;
  }

  .col-8 {
    width: 67%;
  }
}

/* social-links */

@media screen and (max-width: 992px) {
  .social-links {
    margin-top: var(--size-3);
  }
}

a.social-link {
  --social-link-size: 2rem;

  display: inline-flex;
  width: var(--social-link-size);
  height: var(--social-link-size);
  justify-content: center;
  align-items: center;

  text-decoration: none;
  color: inherit;
}

.social-link:hover {
  color: var(--darker-primary);
}

a.social-link-youtube:hover {
  color: #ff0000;
}

a.social-link-twitter:hover {
  color: #1da1f2;
}

a.social-link-instagram:hover {
  color: #f81b4e;
}

a.social-link-gitlab:hover {
  color: #605f9f;
}

/* profile */

#about-me.diagonal::before {
  top: var(--size-3);
}

.profile {
  --profile-size: 6rem;

  border-radius: 50%;
  width: var(--profile-size);
  height: var(--profile-size);

  position: absolute;
  top: var(--size-3);
  left: 50%;
  transform: translateX(-50%);
}

/* calendar photos */

.calendar-photos-wrapper {
  position: relative;
}

.calendar-photos-wrapper img.calendar-photo {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.calendar-photos-wrapper img.calendar-photo:first-of-type {
  filter: brightness(50%);
  margin-bottom: 50px;
}

.calendar-photos-wrapper img#calendar-arrow {
  position: absolute;
  z-index: 0;
  left: 50%;
  transform: translate(-50%, -80%);
  -webkit-filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.8));
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.8));
}

/* mockups */

.mockup {
  position: relative;
  display: inline-block;
  background-image: url("../images/mockup-none_nexus6p-portrait.png");
  /* background-size: 100%; */
  width: 200px;
  height: 409px;
  padding-top: 42px;
}

.mockup > * {
  margin-left: -1px;
}

.mockup-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  font-size: 2rem;
  color: var(--favorite);
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.mockup-btn:focus {
  outline: none;
}

.mockup-btn.play {
  display: none;
}

.mockup:hover .mockup-btn {
  display: block;
}
