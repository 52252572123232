.nav {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  height: var(--size-2);
  background-color: rgba(0, 0, 0, 0.8);
  transition: background 0.5s;
}

body:not([data-section]) .nav:not(:hover),
body[data-section="#header"] .nav:not(:hover) {
  background-color: transparent;
}

.nav:hover {
  background: black;
}

.nav-body {
  position: relative;
}

.nav-body a {
  text-transform: uppercase;
  color: var(--color);
  text-decoration: none;
  position: relative;
  z-index: 1;
}

.nav-content {
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
}

.nav-menu {
  position: absolute;
  right: 0;
  text-align: right;
}

.nav-menu-toggler {
  width: 48px;
  display: inline-flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.nav-menu-items {
  background-color: black;
  position: absolute;
  right: 0;
  top: -200px;
  transition: all 0.5s;
}

body:not(.is-mobile) .nav-menu:hover .nav-menu-items {
  top: 100%;
}

.nav-menu-items > a:not(:first-child) {
  display: block;
  padding: calc(var(--size-1) / 2);
  padding-left: var(--size-2);
  padding-right: var(--size-1);
}

.nav-menu-items > a:hover {
  color: white;
}

.nav-menu-items > a.active {
  font-style: italic;
  color: var(--normal-grey);
}

.nav.full,
.nav.full .nav-body,
.nav.full .nav-menu {
  position: static;
}

.nav.full .nav-menu-items {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.8);
  text-align: center;
  font-size: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
